import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Box,
  Paper,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import * as yup from "yup";

import FileUpload from "../FileUpload/FileUpload";
import SectionProofImage from "./SectionProofImage";
import type { IRejectFormData } from "./types";
import { IStep } from "@/types";
import theme from "@/utils/theme";
import { deepOrange } from "@mui/material/colors";
import TagIcon from "@mui/icons-material/Tag";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { StyledReviewWrapper } from "./Review";

const uploadSchema = yup.object().shape({
  feedback: yup.string().min(2).required(),
  fileId: yup.string().optional(),
});

interface IChangesProps {
  disabled?: boolean;
  onSubmitChanges: (formData: IRejectFormData) => void;
  onChangeStep: (step: IStep) => void;
  proofImage: string;
}

const Changes: FunctionComponent<IChangesProps> = ({
  onSubmitChanges,
  onChangeStep,
  proofImage,
}: IChangesProps) => {
  const [isFormValid, setIsValidForm] = useState(false);
  const [formData, setFormData] = useState<IRejectFormData>({
    feedback: "",
  });
  const { t } = useTranslation("DesignApproval");

  useEffect(() => {
    uploadSchema
      .validate(formData)
      .then(() => {
        setIsValidForm(true);
      })
      .catch(() => {
        setIsValidForm(false);
      });
  }, [formData]);

  return (
    <Card>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmitChanges(formData);
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: "#E6FCF9",
                color: theme.colors.black,
                border: `1px solid #ebebeb`,
                width: 56,
                height: 56,
              }}
              variant="rounded"
            >
              G
            </Avatar>

            <TextField
              placeholder={t("changes.feedback") || ""}
              variant="outlined"
              multiline
              rows={3}
              style={{ width: "100%" }}
              value={formData.feedback}
              onChange={(event) => {
                setFormData((prevState) => {
                  return {
                    ...prevState,
                    feedback: event?.target.value,
                  };
                });
              }}
            />
          </Box>
        </CardContent>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
            backgroundColor: "#ebebeb",
          }}
        >
          <Box sx={{ flex: "1 0 auto", padding: "16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1, // Space between the columns
              }}
            >
              <IconButton disabled size="small">
                <SentimentSatisfiedAltIcon />
              </IconButton>

              <IconButton disabled size="small">
                <AlternateEmailIcon />
              </IconButton>

              <IconButton disabled size="small">
                <TagIcon />
              </IconButton>

              <FileUpload
                onUpload={(fileId: string) => {
                  setFormData((prevState) => {
                    return {
                      ...prevState,
                      fileId,
                    };
                  });
                }}
              />
            </Box>
          </Box>

          <Box sx={{ flex: "0 0 auto" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1, // Space between the columns
              }}
            >
              <Button
                onClick={() => onChangeStep("review")}
                sx={{
                  color: theme.colors.coolBlue,
                  marginRight: 2,
                }}
              >
                {t("changes.cancel")}
              </Button>
              <Button
                disabled={!isFormValid}
                variant="contained"
                sx={{ marginRight: 2 }}
                onClick={() => onSubmitChanges(formData)}
              >
                {t("changes.submit")}
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Card>
  );
};

export default Changes;
