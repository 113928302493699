import { defaultUiState, uiState } from "@/contexts/uiState";
import { Container } from "@mui/material";
import { useParams } from "next/navigation";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import Footer from "../Footer";
// import { Navbar } from "../molecules";

import dynamic from "next/dynamic";

const Navbar = dynamic(() => import("../molecules/Navbar"), {
  ssr: false,
});

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const params = useParams();
  const [ui, setState] = useRecoilState(uiState);

  useEffect(() => {
    if (params && !params.proofId) {
      setState(defaultUiState);
    }
  }, [params, ui.color1]);

  if (params && params.proofId) {
    // Don't render a container if its a proof view
    // We need this so we can control the bg in the client route/component
    return (
      <>
        <Navbar />
        {children}
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Container sx={{ py: 4 }}>{children}</Container>
      <Footer />
    </>
  );
};

export default AppLayout;
