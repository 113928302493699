import React, { Fragment } from "react";
import { Box, Skeleton } from "@mui/material";

const SkeletonLoader = ({ count = 5 }: { count?: number }) => (
  <Box sx={{ display: "flex", mb: 3, gap: 3 }}>
    {[...Array(count)].map((item) => (
      <Skeleton
        key={item}
        variant="rounded"
        animation="wave"
        sx={{
          borderRadius: "9px",
          width: { xs: "100%", md: 235 },
          height: { xs: 181, md: 200 },
        }}
      />
    ))}
  </Box>
);

const ImageListLoader: React.FC = () => {
  return (
    <Box>
      <Box sx={{ mt: 3, display: { xs: "none", md: "block" } }}>
        <SkeletonLoader />
        <SkeletonLoader />
        <SkeletonLoader />
        <SkeletonLoader />
      </Box>
      <Box sx={{ mt: 3, display: { xs: "block", md: "none" } }}>
        <SkeletonLoader count={2} />
        <SkeletonLoader count={2} />
        <SkeletonLoader count={2} />
        <SkeletonLoader count={2} />
      </Box>
    </Box>
  );
};

export default ImageListLoader;
