import React, { useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";

import { ReviewDesign } from "../../atoms";
import Changes from "./Changes";
import type { IRejectFormData } from "./types";
import StartProduction from "./StartProduction";
import Review from "./Review";
import { useSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";
import { IStep } from "@/types";
import { useRouter } from "next/router";
import { ChecklistItem } from "@/components/templates/ProofTemplate";

interface IDesignApprovalProps {
  orderNumber: string;
  proofId: string;
  currentStep: IStep;
  proofImage: string;
  proofInstructions: string;
  fullSizeImageUrl?: string;
  proofChecklist: ChecklistItem[];
  checklistTitle: string;
  checklistDescription: string;
  checklistEnabled: boolean;
  hasSeenAllImages: boolean;
  proofImages: any[];
}

const DesignApproval: React.FC<IDesignApprovalProps> = (
  props: IDesignApprovalProps
) => {
  const [currentStep, setCurrentStep] = useState<IStep>(props.currentStep);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const corkscrewApi = useCorkscrewApi();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const handleStartProduction = () => {
    if (!corkscrewApi) {
      return;
    }

    setIsSubmitting(true);
    corkscrewApi
      .acceptProof(props.proofId.replace("gid://glide/Proof/", ""))
      .then(() => {
        setIsSubmitting(false);
        // Change to go back
        router.replace(`/orders/${router.query.id}`);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setIsSubmitting(false);
        enqueueSnackbar(
          "Could not mark proof as Approved, please try again or contact us",
          { variant: "error" }
        );
      });
  };

  const handleFeedbackSubmit = (feedback: IRejectFormData) => {
    if (!corkscrewApi) {
      return;
    }

    corkscrewApi
      .rejectProof(props.proofId, feedback.feedback, feedback.fileId)
      .then(() => {
        setIsSubmitting(false);
        enqueueSnackbar("Feedback sent!", { variant: "success" });
        // Todo
        router.replace(`/orders/${router.query.id}`);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        setIsSubmitting(false);

        enqueueSnackbar(
          "Could not mark proof as Approved, please try again or contact us",
          { variant: "error" }
        );
      });
  };

  const renderStep = (currentStep: IStep) => {
    switch (currentStep) {
      case "review":
        return (
          <Review
            proofImages={props.proofImages}
            proofImage={props.proofImage}
            onChangeStep={(step: IStep) => setCurrentStep(step)}
            proofInstructions={props.proofInstructions}
            fullSizeImageUrl={props.fullSizeImageUrl}
            proofChecklist={props.proofChecklist}
            checklistTitle={props.checklistTitle}
            checklistDescription={props.checklistDescription}
            checklistEnabled={props.checklistEnabled}
            hasSeenAllImages={props.hasSeenAllImages}
          />
        );
      case "review-complete":
        return <ReviewDesign proofImage={props.proofImage} />;
      case "changes":
        return (
          <Changes
            disabled={isSubmitting}
            onSubmitChanges={handleFeedbackSubmit}
            onChangeStep={(step: IStep) => setCurrentStep(step)}
            proofImage={props.proofImage}
          />
        );
      case "start-production":
        return (
          <StartProduction
            disabled={isSubmitting}
            onChangeStep={(step: IStep) => setCurrentStep(step)}
            onStartProductionClick={handleStartProduction}
            proofImage={props.proofImage}
          />
        );
    }
  };

  return <>{renderStep(currentStep)}</>;
};

export default DesignApproval;
