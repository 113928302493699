"use client";
import React, { createContext, useEffect, useReducer } from "react";
import { websiteApiFactory } from "@/api/websiteapi";
import { atom, useRecoilState } from "recoil";
import { localStorageEffect } from "./effects";

const websiteApi = websiteApiFactory("");

export type Cart = {
  id?: string;
  quantity: number;
  status: "uninitialized" | "fetching" | "creating" | "updating" | "idle";
};

export const CartContext = createContext<Cart>({
  quantity: 0,
  status: "uninitialized",
});

export const cartState = atom<Cart>({
  key: "cart",
  default: {
    quantity: 0,
    status: "uninitialized",
  },
  effects: [localStorageEffect("si_cart")],
});

function CartProvider({ children }: { children: React.ReactNode }) {
  const [cart, setCart] = useRecoilState(cartState);

  useEffect(() => {
    if (cart.id) {
      setCart((cur) => {
        return { ...cur, status: "fetching" };
      });

      websiteApi.getCart(cart.id).then((ct) => {
        setCart((cur) => {
          return { ...cur, status: "idle", quantity: ct.items.length };
        });
      });
    } else {
      setCart((cur) => {
        return { ...cur, status: "idle", quantity: 0 };
      });
    }
  }, [cart.id]);

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
}

export { CartProvider };
