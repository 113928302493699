import React from "react";
import { Box, CircularProgress } from "@mui/material";

export type LoaderProps = {
  size?: number;
};

const Loader: React.FC<LoaderProps> = ({ size = 24 }: LoaderProps) => {
  return (
    <Box display="flex" width="100%" justifyContent="center" mt={4}>
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;
