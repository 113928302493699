import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
  Popover,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "next-i18next";
import { useSnackbar } from "notistack";
// import Bugsnag from "@bugsnag/js";
import { defaultTranslations, DimensionsText } from "@/components/atoms";
import { getCorkscrewBaseUrl } from "@/utils/config";
import { MaterialType, QuoteInterface, PublicApprovedArtwork } from "@/types";
import theme from "@/utils/theme";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import { useUser } from "@/contexts/userContext";
import { websiteApiFactory } from "@/api/websiteapi";
import errorValidator from "@/api/errorValidator";
import useCart from "@/hooks/useCart";

export type DesignItemPageProps = {
  design: PublicApprovedArtwork;
  allMaterials: {
    data: MaterialType[];
  };
  onClick?: (design: PublicApprovedArtwork) => void;
};

const popoverStyles = {
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: 2,
  paddingBottom: 3,
};

const StyledButton = styled(Button)(() => ({
  "&:nth-of-type(2)": {
    marginTop: 2,
  },
}));

const currencySymbols = {
  EUR: "€",
  GBP: "£",
  USD: "$",
};

export const DesignItem: React.FC<DesignItemPageProps> = (
  props: DesignItemPageProps
) => {
  const { design, allMaterials } = props;
  const isPortrait = design.heightMM > design.widthMM;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const {
    state: { profile, token, canReorder },
  } = useUser();

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const { t } = useTranslation("DesignItem");
  const { enqueueSnackbar } = useSnackbar();
  const userCtx = useUser();
  const corkscrewApi = useCorkscrewApi();
  const websiteApi = websiteApiFactory(userCtx.state.token || "");
  const [quoteReq, setQuoteReq] = useState<QuoteInterface | null>(null);
  const [quote, setSetQuote] = useState<any>(null);
  const [quantity, setQuantity] = useState(design.quantity);
  const defaultShape = "custom";
  const { linesAdd, status } = useCart();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    design: PublicApprovedArtwork
  ) => {
    setAnchorEl(event.currentTarget);
    fetchQuote(design);
  };

  const fetchQuote = (
    design: PublicApprovedArtwork,
    customQuantity?: number
  ) => {
    if (!corkscrewApi) return;

    if ((customQuantity && customQuantity > 0) || quantity > 0) {
      setIsAddingToCart(true);

      const shipToCountryCodeParts = (profile?.languageCode || "en-gb").split(
        "-"
      );
      const shipToCountryCode =
        shipToCountryCodeParts.length === 2 ? shipToCountryCodeParts[1] : "GB";

      const payload: QuoteInterface = {
        w: design.widthMM,
        h: design.heightMM,
        materialSku: design.materialSku,
        u: "mm",
        productSku: design.productSku,
        shipToCountryCode: shipToCountryCode.toUpperCase(),
        currencyCode: profile?.currencyCode || "GBP",
        quantity: customQuantity || quantity,
      };

      setQuoteReq(payload);
      websiteApi
        .calcQuote(payload)
        .then((resp) => {
          setIsAddingToCart(false);
          setSetQuote(resp);
        })
        .catch((err) => errorValidator(err));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setQuantity(1);
  };

  const addToCart = async () => {
    if (!quoteReq) return;

    setIsAddingToCart(true);

    try {
      await linesAdd([design]);
    } catch (err) {
      enqueueSnackbar("could not add to cart", {
        variant: "error",
      });
    }

    setIsAddingToCart(false);
    enqueueSnackbar("Added to cart", { variant: "success" });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const materialName = useMemo(
    () =>
      allMaterials.data.find((m) => m.materialSku === design.materialSku)
        ?.name || design.materialSku,
    [design.materialSku, allMaterials]
  );

  const projectIdMeta = design?.meta?.find(
    (item) => item.ns === "designhuddle" && item.key == "projectId"
  );

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={popoverStyles}
      >
        <Box sx={popoverStyles}>
          <Box sx={{ marginTop: 0, mb: 3 }}>
            <Typography variant="h4">{design.designCode}</Typography>
            <Box sx={{ marginTop: 1, mb: 1 }}>
              <Typography sx={{ fontWeight: "medium" }}>
                {t("size")}:
              </Typography>{" "}
              <DimensionsText
                width={design.widthMM}
                height={design.heightMM}
                units="mm"
                displayAs="cm"
                translations={defaultTranslations}
              />
            </Box>
            <Typography sx={{ marginTop: 1, mb: 1 }}>
              <Typography sx={{ fontWeight: "medium" }}>
                {t("material")}:
              </Typography>{" "}
              {materialName} -ddd
            </Typography>
            <Typography sx={{ marginTop: 1, mb: 1 }}>
              <Typography sx={{ fontWeight: "medium" }}>
                {t("quantity")}:
              </Typography>{" "}
              <TextField
                type="number"
                defaultValue={quantity}
                placeholder="Enter quantity"
                inputProps={{ maxlength: 1000 }}
                disabled={canReorder === false}
                sx={{
                  border: "none !important",
                  mt: 1,
                  width: 100,
                }}
                onChange={(e) => {
                  setQuantity(Number(e.target.value));
                  setTimeout(
                    () => fetchQuote(design, Number(e.target.value)),
                    500
                  );
                }}
                error={(quantity && quantity <= 0) || undefined}
              />
            </Typography>
          </Box>

          {canReorder ? (
            <StyledButton
              variant="contained"
              disabled={isAddingToCart || quantity <= 0}
              onClick={addToCart}
              sx={{ minWidth: { xs: 300, md: 350 } }}
            >
              {isAddingToCart ? (
                <CircularProgress size={26} />
              ) : (
                <>
                  {quote ? (
                    <>
                      {t("buyButton")}
                      <Box component="span" sx={{ ml: 1 }}>
                        {currencySymbols[quoteReq?.currencyCode || "GBP"]}
                        {quoteReq?.shipToCountryCode === "GB"
                          ? quote?.priceIncTax
                          : quote?.priceExTax}
                      </Box>
                    </>
                  ) : null}
                </>
              )}
            </StyledButton>
          ) : null}
          {canReorder && projectIdMeta ? (
            <Button
              variant="outlined"
              sx={{ marginTop: 1 }}
              fullWidth
              href={`https://graphic.stickerit.co/login?token=${token}&redirectTo=/design/${projectIdMeta.value}`}
            >
              {t("editButton")}
            </Button>
          ) : null}
        </Box>
      </Popover>
      <ImageListItem key={design.proofId} sx={{ overflow: "hidden" }}>
        <img
          src={`${getCorkscrewBaseUrl()}${design.proofBitmapFileUrl}&w=200`}
          loading="lazy"
          alt="Image"
          style={{
            objectFit: "contain",
            objectPosition: "center center",
            minHeight: "168px",
            maxHeight: "200px",
            borderRadius: "10px",
            border: `1px solid ${theme.colors.greyPlaceholder}`,
          }}
          onClick={() => (props?.onClick ? props?.onClick(design) : null)}
        />
        <ImageListItemBar
          sx={{
            ".MuiImageListItemBar-title": {
              fontWeight: "600",
            },
          }}
          position="below"
          title={design.designCode}
          subtitle={
            <>
              <DimensionsText
                width={design.widthMM}
                height={design.heightMM}
                units="mm"
                displayAs="cm"
                translations={defaultTranslations}
              />{" "}
              on {materialName}
            </>
          }
          actionIcon={
            <IconButton
              aria-describedby={id}
              onClick={(e) => handleClick(e, design)}
              sx={{ mt: 1 }}
              aria-label={`info about ${design.designCode}`}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
      </ImageListItem>
    </>
  );
};

export default DesignItem;
