import React from "react";
import { useTranslation } from "next-i18next";
import { Box, Button, Grid, Typography } from "@mui/material";

import SectionProofImage from "./SectionProofImage";
import { colors } from "@/utils/theme";
import { IStep } from "@/types";

export interface IStartProductionProps {
  disabled?: boolean;
  onStartProductionClick: () => void;
  onChangeStep: (step: IStep) => void;
  proofImage: string;
}

const StartProduction: React.FC<IStartProductionProps> = ({
  onStartProductionClick,
  onChangeStep,
  proofImage,
}: IStartProductionProps) => {
  const { t } = useTranslation("DesignApproval");

  return (
    <Grid
      container
      sx={{
        marginTop: 4,
        marginBottom: 4,
        border: "4px solid " + colors.greenLight,
        paddingTop: 3,
        paddingBottom: 3,
        background: colors.white,
      }}
    >
      <Grid item xs={12}>
        <Box mb={6} p={3}>
          <Typography align="center" variant="h5">
            {t("production.message")}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={onStartProductionClick}
          style={{ marginBottom: 20 }}
        >
          {t("production.startProduction")}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button variant="outlined" onClick={() => onChangeStep("review")}>
          {t("production.goBack")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default StartProduction;
