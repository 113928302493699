import { OrderListEdge } from "@/api/corkcrew";
import theme from "@/utils/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import React from "react";
import { NoDataFound, OrderListItem } from "../atoms";

interface IProps {
  orders: OrderListEdge[];
  ordersPagePath: string;
  canDownloadInvoice: boolean;
}

const OrderListTable: React.FC<IProps> = ({
  orders,
  ordersPagePath,
  canDownloadInvoice,
}: IProps) => {
  const { t, ready } = useTranslation("OrderList");
  const { t: noDataErrors, ready: nReady } =
    useTranslation("NoDataFoundErrors");

  return (
    <Box>
      {orders && orders.length > 0 ? (
        <>
          <Box
            sx={{
              display: { xs: "none", sm: "initial" },
            }}
          >
            <Box
              component="header"
              display="flex"
              sx={{
                p: 1.5,
                border: {
                  xs: "none",
                  md: `1px solid ${theme.colors.greyPlaceholder}`,
                },
              }}
            >
              <Box sx={{ flex: 1, display: "flex" }}>
                <Typography
                  sx={{ fontWeight: 600, textTransform: "uppercase" }}
                >
                  {t("orderNumber")}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{ fontWeight: 600, textTransform: "uppercase" }}
                >
                  {t("orderDate")}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{ fontWeight: 600, textTransform: "uppercase" }}
                >
                  {t("status")}
                </Typography>
              </Box>
              {canDownloadInvoice ? (
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{ fontWeight: 600, textTransform: "uppercase" }}
                  >
                    {t("action")}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              borderTop: "none",
              border: {
                xs: "none",
                md: `1px solid ${theme.colors.greyPlaceholder}`,
              },
              p: { xs: 0, md: 2 },
            }}
          >
            {orders.map((order) => {
              return (
                <React.Fragment key={order.node.id}>
                  <OrderListItem
                    orderId={order.node.id}
                    ordersPagePath={ordersPagePath}
                    orderNumber={order.node.name}
                    orderStatus={order.node.proofStatus}
                    orderLineItems={order.node.lineItems}
                    orderDate={
                      new Date(order.node.createdAt as unknown as string)
                    }
                    canDownloadInvoice={canDownloadInvoice}
                  />
                </React.Fragment>
              );
            })}
          </Box>
        </>
      ) : (
        <NoDataFound defaultStyles message={noDataErrors("noOrders")} />
      )}
    </Box>
  );
};

export default OrderListTable;
