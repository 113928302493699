import { PublicApprovedArtwork, QuoteInterface } from "@/types";
import { IPublicAddress } from "@kartdavid/corkscrew-types/public";
import axios from "axios";

export type DimensionUnit = "cm" | "in" | "mm" | "m";
export type Dimensions = {
  unit: DimensionUnit;
  width: number;
  height: number;
};

const getQuoteQueryParams = (quote: QuoteInterface) => {
  const params = new URLSearchParams();
  params.set("w", quote.w.toString());
  params.set("h", quote.h.toString());
  params.set("u", quote.u);
  params.set("quantity", quote.quantity.toString());
  params.set("productSku", quote.productSku);
  params.set("materialSku", quote.materialSku);
  params.set("currencyCode", quote.currencyCode);
  params.set("shipToCountryCode", quote.shipToCountryCode);

  return params.toString();
};

/* ************************************************************************** */
/*                               WEBSITE API                                */
/* ************************************************************************** */
export const websiteApiFactory = (token: string) => {
  const baseUrl = process.env.NEXT_PUBLIC_WEBSITE_API_URL;
  const storeHash = process.env.NEXT_PUBLIC_STORE_HASH;
  const defaultHeaders: Record<string, string> = {
    "x-store-hash": storeHash || "",
    accept: "application/json",
    "content-type": "application/json",
  };

  return {
    getCart: (id: string) => {
      return fetch(`${baseUrl}/v4/cart/${id}`, {
        headers: defaultHeaders,
      }).then((resp) => {
        if (resp.status !== 200) {
          throw new Error("cart not found");
        }

        return resp.json();
      });
    },

    createCart: (currencyCode: string): Promise<{ id: string }> => {
      return fetch(`${baseUrl}/v4/cart`, {
        method: "POST",
        headers: defaultHeaders,
        body: JSON.stringify({
          storeHash,
          currencyCode,
        }),
      }).then((resp) => resp.json());
    },

    addToCart: (
      id: string,
      customerId: string,
      items: PublicApprovedArtwork[]
    ): Promise<{ id: string }> => {
      return fetch(`${baseUrl}/v4/cart/${id}/items`, {
        method: "POST",
        headers: defaultHeaders,
        body: JSON.stringify({
          cartId: id,
          reorder: items.map((item) => {
            return {
              cartId: id,
              customerId,
              designCode: item.designCode,
              proofId: item.proofId,
            };
          }),
        }),
      }).then((resp) => resp.json());
    },

    calcQuote: (quote: QuoteInterface): Promise<{}> => {
      const params = getQuoteQueryParams(quote);
      return fetch(`${baseUrl}/v4/calc?${params}`, {
        headers: defaultHeaders,
      }).then((resp) => resp.json());
    },

    prepareProduct: (quote: QuoteInterface): Promise<{ variantId: string }> => {
      const params = getQuoteQueryParams(quote);

      return fetch(`${baseUrl}/v4/shopify/product?${params}`, {
        method: "POST",
        headers: defaultHeaders,
      }).then((resp) => resp.json());
    },
  };
};
