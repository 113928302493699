import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";

interface IProps {
  setOpenVideoModal: React.SetStateAction<any>;
  openVideoModal: boolean;
}
const ModalWithVideo: React.FC<IProps> = ({
  openVideoModal,
  setOpenVideoModal,
}: IProps) => {
  const handleClose = () => {
    setOpenVideoModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openVideoModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={openVideoModal}>
        <React.Fragment>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box
                sx={{
                  paddingTop: 5,
                  paddingBottom: 5,
                  backgroundColor: "white",
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography variant="h4" align="center">
                    Die cut sticker
                  </Typography>
                  <Typography variant="h4" align="center">
                    Cut line
                  </Typography>
                  <Box mt={5}>
                    <Typography variant="subtitle1" align="center">
                      This will contain info about the cutlines used in the
                      design. Could create short videos to show what that
                      cutline looks like on the final sticker.
                    </Typography>
                  </Box>
                </Box>
                <Grid item xs={12} sx={{ marginTop: 4 }}>
                  <img
                    style={{
                      width: "100%",
                      display: "block",
                    }}
                    src={"VideoImage"}
                    alt="just-for-test"
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      </Fade>
    </Modal>
  );
};

export default ModalWithVideo;
