// Routes
export const ROUTE_ACCOUNT = "/";
export const ROUTE_PROOFS = "/proofs";
export const ROUTE_DESIGNS = "/designs";
export const ROUTE_ORDERS = "/orders";
export const ROUTE_REFERRALS = "/referrals";
export const ROUTE_PROOF_VIEW = "/orders/:orderNumber/proofs/:proofId";
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGOUT = "/logout";
export const ROUTE_ORDER_VIEW = "/orders/:orderNumber";
export const ROUTE_REGISTER = "/register";
export const ROUTE_EMAIL_REGISTER = "/register/email";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_REDIR_ORDER_SUCCESS = "/redir/order-success";
export const ROUTE_ORDER_CONFIRMATION_SIMPLE = "/order-confirmation";

// Steps
export const STEP_PROOF = "proof";
export const STEP_ORDER = "order";
export const STEP_MANUFACTURING = "manufacturing";
export const STEP_SHIPPING = "shipping";

// Date formats
export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "EEE, d MMM yyyy ";
// Date formats from webapp
export const TIME_FORMAT = "p ";

// Progress statuses
export const ORDER_PLACED = "OrderPlaced";
export const DESIGN_IN_PROGRESS = "DesignInProgress";
export const FEEDBACK_REQUIRED = "FeedbackRequired";
export const PRINTING = "Printing";
export const CUTTING = "Cutting";
export const QUALITY_CONTROL = "QualityControl";

export const PRICING_CALCULATOR_ID = "pc";

// File types
export const ACCEPTABLE_FILE_TYPES = [
  "image/*",
  "application/postscript",
  "image/vnd.adobe.photoshop",
  "application/vnd.adobe.photoshop",
  "application/gzip",
  "application/zip",
  "application/vnd.rar",
  "application/pdf",

  "application/coreldraw",
  "application/x-coreldraw",
  "application/cdr",
  "application/x-cdr",
  "application/vnd.corel-draw",

  "image/cdr",
  "image/x-cdr",
  "image/x-coreldraw",
];

export const PREVIEWABLE_FILE_TYPES = [
  "image/bmp",
  "image/png",
  "image/webp",
  "image/jpeg",
  "image/svg+xml",
  "image/avif",
  "application/pdf",
];
