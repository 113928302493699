import React, { useContext, useEffect, useReducer } from "react";
import type { IPublicUserGetMeResponse } from "@kartdavid/corkscrew-types/public";
import useLocalStorage from "@/hooks/useLocalStorage";
import { getCorkscrewBaseUrl } from "@/utils/config";

const initialState: State = {
  ready: false,
  loggedIn: false,
  userId: undefined,
  profile: undefined,
  token: undefined,
  cartItems: 0,
  redirectTo: "/orders",
  cartUrl: "/cart",
  canReorder: false,
  canDownloadInvoice: false,
  apiEndpoint: getCorkscrewBaseUrl(),
  orgId: "",
};

function userReducer(state: State, action: Action) {
  const actionType = action.type;

  switch (actionType) {
    case "logout":
      return {
        ...initialState,
        orgId: state.orgId,
        apiEndpoint: state.apiEndpoint,
      };
    case "setLoggedIn":
      if (!action.loggedIn) {
        return initialState;
      }

      return {
        ...state,
        loggedIn: action.loggedIn,
        userId: action.userId || state.userId,
        token: action.token,
        shopifyOrderUrl: action.shopifyOrderUrl,
        cartUrl: action.cartUrl,
        profile: undefined,
        canDownloadInvoice: action.canDownloadInvoice,
        canReorder: action.canReorder,
        apiEndpoint: action.apiEndpoint,
        orgId: action.orgId,
      };
    case "setProfile": {
      return {
        ...state,
        profile: action.profile,
      };
    }
    case "setCartItems": {
      return {
        ...state,
        cartItems: action.cartItems,
      };
    }
    case "setReady": {
      return {
        ...state,
        ready: action.value,
      };
    }
    case "setBrand": {
      return {
        ...state,
        ...action.brand,
      };
    }
    case "setOrgId": {
      return {
        ...state,
        orgId: action.orgId,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${actionType}`);
    }
  }
}

export type Action =
  | {
      type: "setLoggedIn";
      loggedIn: boolean;
      canReorder: boolean;
      userId?: string;
      token?: string;
      cartUrl?: string;
      shopifyOrderUrl?: string;
      canDownloadInvoice: boolean;
      apiEndpoint: string;
      orgId: string;
    }
  | { type: "setProfile"; profile: IPublicUserGetMeResponse }
  | { type: "setOrgId"; orgId: string }
  | { type: "setReady"; value: boolean }
  | { type: "logout" }
  | { type: "setCartItems"; cartItems: number }
  | {
      type: "setBrand";
      brand: {
        logoHorizontal?: {
          url: string;
        };
        logoSquare?: {
          url: string;
        };
      };
    };

export type Dispatch = (action: Action) => void;
export type State = {
  loggedIn: boolean;
  canDownloadInvoice: boolean;
  userId?: string;
  shopifyOrderUrl?: string;
  cartUrl?: string;
  token?: string;
  profile?: IPublicUserGetMeResponse & { avatarUrl?: string };
  ready: boolean;
  cartItems?: number;
  redirectTo: string;
  canReorder: boolean;
  apiEndpoint: string;
  orgId: string;

  logoHorizontal?: {
    url: string;
  };
  logoSquare?: {
    url: string;
  };
};

export const UserStateContext = React.createContext<{
  state: State;
  dispatch: Dispatch;
}>({
  state: initialState,
  dispatch: () => {
    // dispatch
  },
});

function UserProvider({ children }: { children: React.ReactNode }) {
  const [storageContext, setStorageContext] = useLocalStorage(
    "localContext",
    initialState
  );

  const [state, dispatch] = useReducer(userReducer, storageContext);

  useEffect(() => {
    setStorageContext(state);
  }, [state]);

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  const { state, dispatch } = context;
  return { state, dispatch };
}

export { UserProvider, useUser };
