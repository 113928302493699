import React, { useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import theme from "@/utils/theme";
import CancelIcon from "@mui/icons-material/Cancel";
import { getCorkscrewBaseUrl } from "@/utils/config";
import Image from "next/image";
import { PublicApprovedArtwork } from "@/types";

export type DesignModalProps = {
  open: boolean;
  onClose: () => void;
  selectedDesign: PublicApprovedArtwork | null;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: 500 },
  height: { xs: 360, md: 500 },
  bgcolor: theme.colors.white,
  boxShadow: 24,
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  outline: "none !important",
};

const DesignModal: React.FC<DesignModalProps> = ({
  open,
  onClose,
  selectedDesign,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
        }}
      >
        <IconButton
          aria-describedby="close-modal"
          onClick={onClose}
          sx={{ position: "absolute", right: "8px", top: "8px" }}
        >
          <CancelIcon />
        </IconButton>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            src={`${getCorkscrewBaseUrl()}${
              selectedDesign?.proofBitmapFileUrl
            }`}
            loading="lazy"
            width={400}
            height={400}
            alt="Image"
            style={{
              objectFit: "contain",
              objectPosition: "center center",
              borderRadius: "10px",
              margin: "20px auto",
              border: `1px solid ${theme.colors.greyPlaceholder}`,
              backgroundColor: `${theme.colors.white}`,
            }}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img
            src={`${getCorkscrewBaseUrl()}${
              selectedDesign?.proofBitmapFileUrl
            }`}
            loading="lazy"
            width={280}
            height={280}
            alt="Image"
            style={{
              objectFit: "contain",
              objectPosition: "center center",
              borderRadius: "10px",
              margin: "20px auto",
              border: `1px solid ${theme.colors.greyPlaceholder}`,
              backgroundColor: `${theme.colors.white}`,
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DesignModal;
