import routes from "@/utils/routes";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";

const errorValidator = (err: AxiosError<any>) => {
  const checkErrorMessage = (err: AxiosError<any>, customMessage: string) => {
    enqueueSnackbar(customMessage, { variant: "error" });
  };

  if (err && err.response) {
    switch (err.response.status) {
      case 401:
        window.location.href = routes.login;
        break;
      case 404:
        checkErrorMessage(err.response?.data, "Resource not found!");
        break;
      case 500:
        checkErrorMessage(
          err.response?.data,
          "Something went wrong while fetching resource!"
        );
        break;
      case 400:
        checkErrorMessage(err.response?.data, "Your request is invalid!");
        break;
      case 403:
        checkErrorMessage(err.response?.data, "Not allowed");
        break;
      default:
        checkErrorMessage(err.response?.data, "Network error");
        break;
    }
  }
  return err;
};

export default errorValidator;
