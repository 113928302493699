export enum IFilePurpose {
  PurposeGeneric = 0,
  PurposeCustomerArtwork = 1,
  PurposeProof = 2,
  PurposePrintReadyArtwork = 3,
  PurposeDesignWorkingFile = 4,
  PurposeCutFile = 5,
  PurposeProductSpecification = 6,
  PurposeGraphicDesign = 7,
  PurposeGraphicProof = 8,
  PurposeTiliaReadyArtwork = 9,
}

export interface IPutFileResponse {
  id: string;
  fileNumber: string;
  purpose: string;
}
