import { IFilePurpose } from "./types";
import type { IPutFileResponse } from "./types";

import { Button, CircularProgress, IconButton } from "@mui/material";
import { createId } from "@paralleldrive/cuid2";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import useCorkscrewApi from "@/hooks/useCorkscrewApi";
import { UserStateContext } from "@/contexts";
import { AxiosProgressEvent } from "axios";
import errorValidator from "@/api/errorValidator";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

/** const useStyles = makeStyles((theme: Theme) => ({
  uploadButton: {
    color: 'white',
    minWidth: '140px',
    maxWidth: '140px',
    marginTop: '-1px',
    marginBottom: '-1px',
    marginRight: '-1px',
    textAlign: 'center',
  },
  filename: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  buttonProgress: {
    color: theme.colors.greenDark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}))**/

export type IFileUpload = { id: string } & any;

const newFile = (): IFileUpload => {
  return {
    id: createId(),
    file: null,
    progress: 0,
    purpose: "",
    state: "init",
    uploadedId: "",
  };
};

interface IProps {
  onUpload: (fileId: string) => void;
  disabled?: boolean;
}

const FileUpload: FC<IProps> = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hover, setHover] = useState(false);
  const { t } = useTranslation("DesignApproval");
  const purpose = IFilePurpose.PurposeCustomerArtwork;
  const corkscrewApi = useCorkscrewApi();

  const [fileToUpload, setFileToUpload] = useState<IFileUpload>(newFile());

  useEffect(() => {
    if (fileToUpload.state === "done") {
      props.onUpload(fileToUpload.uploadedId);
    }
  }, [fileToUpload]);

  const userCtx = useContext(UserStateContext);

  const handleUploadFile = async (event: any) => {
    if (!userCtx || !userCtx.state.token || !corkscrewApi) {
      return;
    }

    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("purpose", purpose.toString());

      setFileToUpload((prevFile: any) => ({
        ...prevFile,
        file,
        state: "uploading",
      }));

      const resp = await corkscrewApi.client.post<any>(`/v1/file`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
        onUploadProgress: (pe: AxiosProgressEvent) => {
          setFileToUpload((prevFile: { progress: number; state: any }) => ({
            ...prevFile,
            progress: (pe.loaded / (pe.total || pe.loaded)) * 100,
            state: prevFile.progress >= 99 ? "finalizing" : prevFile.state,
          }));
        },
      });
      console.log(resp.data);

      setFileToUpload((prevFile: any) => ({
        ...prevFile,
        state: "done",
        uploadedId: resp.data.id,
      }));
    } catch (error) {
      setFileToUpload((prevFile: any) => ({
        ...prevFile,
        state: "init",
      }));
      errorValidator(error);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <input
        id="file-upload"
        type="file"
        onChange={handleUploadFile}
        hidden
        ref={inputRef}
        disabled={props.disabled}
      />

      {fileToUpload.file && fileToUpload.state === "done" ? (
        <IconButton
          size="small"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => {
            if (hover) {
              setFileToUpload((prevFile: any) => ({
                state: "init",
              }));
              setHover(false);
            }
          }}
        >
          {hover ? (
            <DeleteSweepIcon />
          ) : (
            <FileDownloadDoneIcon sx={{ color: "green" }} />
          )}
        </IconButton>
      ) : null}

      {fileToUpload.state === "uploading" ? (
        <IconButton size="small">
          <CircularProgress
            size={24}
            variant="determinate"
            value={fileToUpload.progress}
          />
        </IconButton>
      ) : null}
      {!fileToUpload.file ? (
        <IconButton
          size="small"
          disabled={props.disabled}
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          <UploadFileIcon />
        </IconButton>
      ) : null}
    </div>
  );
};
export default FileUpload;
