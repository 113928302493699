import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import theme from "@/utils/theme";

export interface IGreenbar {
  status: string;
}

export interface IMessageItem {
  title: string;
  deliveryDate?: string;
  message: string;
  subMessage?: string;
}

export const MessageItem: React.FC<IMessageItem> = ({
  title,
  deliveryDate,
  message,
  subMessage,
}: IMessageItem) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "24px", md: theme.typography.pxToRem(50) },
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(1),
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // marginBottom: theme.spacing(2),
        }}
      >
        {deliveryDate ? (
          <>
            <Typography sx={{ fontSize: "inherit" }}>
              Expected delivery:
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "14px", md: theme.typography.pxToRem(26) },
                marginLeft: theme.spacing(1),
                lineHeight: "20px",
                [theme.breakpoints.down("xs")]: {
                  marginLeft: 0,
                  marginBottom: theme.spacing(1),
                },
              }}
            >
              {deliveryDate}
            </Typography>
          </>
        ) : null}
      </Box>
      <Typography
        sx={{
          maxWidth: { xs: "250px", md: "100%" },
          marign: "0 auto",
          fontSize: { xs: "14px", md: theme.typography.pxToRem(21) },
          lineHeight: "20px",
        }}
      >
        {message}
      </Typography>
      {subMessage ? (
        <Typography
          variant="body2"
          sx={{
            fontSize: theme.typography.pxToRem(16),
            marginTop: theme.spacing(2),
          }}
        >
          {subMessage}
        </Typography>
      ) : null}
    </Box>
  );
};

const RenderStatusMessage = (status: string) => {
  const { t } = useTranslation("OrderStatus");
  if (status === "Payment") {
    return (
      <MessageItem
        title={t(`${status}.title`)}
        message={t(`${status}.description`)}
        subMessage={t("Payment.submessage") || ""}
      />
    );
  }
  return (
    <MessageItem
      title={t(`${status}.title`)}
      message={t(`${status}.description`)}
    />
  );
};

const GreenBar: React.FunctionComponent<IGreenbar> = ({
  status,
}: IGreenbar) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          paddingTop: { xs: 3, md: 6 },
          paddingBottom: { xs: 3, md: 6 },
          paddingLeft: 4,
          paddingRight: 4,
          borderRadius: "9px",
          background: theme.colors.greenBar,
          textAlign: "center",
        }}
      >
        {status ? RenderStatusMessage(status) : null}
      </Box>
    </React.Fragment>
  );
};

export default GreenBar;
