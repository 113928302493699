import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

function isHexOrRgba(color: string): "hex" | "rgb" | "rgba" | "invalid" {
  // Regular expression for hex colors
  const hexRegex = /^#([A-Fa-f0-9]{3}){1,2}$/;

  // Regular expression for rgba colors
  const rgbaRegex =
    /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d*\.?\d+)\s*\)$/;

  // Regular expression for rgb colors (without alpha)
  const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;

  if (hexRegex.test(color)) {
    return "hex";
  } else if (rgbaRegex.test(color)) {
    return "rgba";
  } else if (rgbRegex.test(color)) {
    return "rgb";
  } else {
    return "invalid";
  }
}

function rgbaToHex(rgba: string): string {
  // Extract the RGBA values from the string
  const rgbaValues = rgba.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d+)?\)/
  );

  if (!rgbaValues) {
    throw new Error("Invalid RGBA format");
  }

  // Convert the extracted RGBA values to hexadecimal format
  const r = parseInt(rgbaValues[1]).toString(16).padStart(2, "0").toUpperCase();
  const g = parseInt(rgbaValues[2]).toString(16).padStart(2, "0").toUpperCase();
  const b = parseInt(rgbaValues[3]).toString(16).padStart(2, "0").toUpperCase();

  // Combine the hexadecimal values into a single string
  const hex = `#${r}${g}${b}`;

  return hex;
}

function hexToRgba(hex: string, alpha: number = 1): string {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the hex string depending on its length (3, 6, or 8 characters)
  let r: number,
    g: number,
    b: number,
    a: number = 255;

  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6 || hex.length === 8) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);

    if (hex.length === 8) {
      a = parseInt(hex.slice(6, 8), 16);
      alpha = +(a / 255).toFixed(2);
    }
  } else {
    throw new Error("Invalid HEX format");
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Could be a RGB or
const getContrast = function (hexcolor: string) {
  // If a leading # is provided, remove it
  if (hexcolor.slice(0, 1) === "#") {
    hexcolor = hexcolor.slice(1);
  }

  // Convert to RGB value
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
};

export const getContrastColor = (color: string) => {
  const colourType = isHexOrRgba(color);

  switch (colourType) {
    case "hex": {
      return getContrast(color);
    }
    case "rgb":
    case "rgba": {
      const hexColor = rgbaToHex(color);
      return getContrast(hexColor);
    }
    case "invalid": {
      throw new Error("Invalid color format");
    }
  }
};

export interface IColorsBase {
  greenOriginal: string;
  white: string;
  blue: string;
  blueLight: string;
  blueDark: string;
  green: string;
  greenLight: string;
  greenLightest: string;
  greenDark: string;
  grey: string;
  greyLight: string;
  greyDark: string;
  greyBorder: string;
  greenAlt: string;
  validateFail: string;
  validateOk: string;
  red: string;
  redLight: string;
  redDark: string;
  black: string;
  blackLight: string;
  blackDark: string;
  coolBlue: string;
  coolBlueLight: string;
  coolBlueDark: string;
  greyBackground: string;
  greyDivider: string;
  greenBar: string;
  greenSuperLight: string;
  greyPlaceholder: string;
}

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
    colors: IColorsBase;
  }

  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    colors: IColorsBase;
  }
}

interface IColors extends IColorsBase {
  greenLightest: string;
  orange: string;
  orangeDark: string;
  orangeLight: string;
}

export const colors: IColors = {
  coolBlue: "#0076CF",
  coolBlueLight: "#CCE4f6",
  coolBlueDark: "#004B9D",

  blue: "#141B4D",
  blueLight: "#424279",
  blueDark: "#000026",

  green: "#99F2E4",
  greenLight: "#CCF9F2",
  greenLightest: "#E6FCF9",
  greenDark: "#00B19D",

  greenBar: "#ADF0E4",
  greenOriginal: "#33E4C9",
  greenSuperLight: "#C8FFF4",

  greenAlt: "#1BD114",
  validateFail: "#E40028",
  validateOk: "#1BD114",

  orange: "#FF5900",
  orangeDark: "#C44500",
  orangeLight: "#FFCDB2",

  red: "#F82323",
  redLight: "#FF9191",
  redDark: "#D61313",

  white: "#FFFFFF",

  grey: "#7F8481",
  greyLight: "#AEB4B0",
  greyDark: "#535755",
  greyBorder: "#808381",
  greyBackground: "#F3F3F3",
  greyDivider: "#808381",
  greyPlaceholder: "#0000001A",

  black: "#000000",
  blackLight: "#2C2C2C",
  blackDark: "#000000",
};

const baseTheme = createTheme();

const glideTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          letterSpacing: 0.8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "9px",
          letterSpacing: 0.8,
        },
      },
    },
  },
  typography: {
    fontFamily: ["Poppins", "Arial"].join(","),
    h1: {
      color: colors.blue,
      fontSize: "2rem", // 32px
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "2.5rem", // 40px
      },
      fontWeight: "400", // Regular
      letterSpacing: "-0.025rem", // -0.4px
    },
    h2: {
      color: colors.blue,
      fontSize: "2rem", // 32px
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "2rem", // 32px
      },
      fontWeight: "400", // Regular
      letterSpacing: "-0.02rem", // -0.32px
    },
    h3: {
      color: colors.blue,
      fontSize: "1.5rem", // 24px
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "1.75rem", // 28px
      },
      fontWeight: "400", // Regular
      letterSpacing: "-0.0175rem", // -0.28px
    },
    h4: {
      color: colors.blue,
      fontSize: "1.375rem", // 22px
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "1.625rem", // 26px
      },
      fontWeight: "400", // Regular
      letterSpacing: "-0.01375rem", // -0.26px
    },
    h5: {
      color: colors.blue,
      fontSize: "1.25rem", // 20px
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "1.3125rem", // 21px
      },
      fontWeight: "400", // Regular
      letterSpacing: "-0.013125rem", // -0.21rem
    },
    h6: {
      color: colors.blue,
      fontSize: "1.125rem", // 18px
      fontWeight: "400", // Regular
      letterSpacing: "-0.01125rem", // -0.18px
    },
    subtitle1: {
      color: colors.blue,
      fontSize: "1rem", // 16px
      fontWeight: "400", // Regular
      letterSpacing: "-0.01rem", // -0.16px
    },
    subtitle2: {
      color: colors.blue,
      fontSize: "0.875rem", // 14px
      fontWeight: "500", // Medium
      letterSpacing: "-0.00875rem", // -0.14px
    },
    body1: {
      color: colors.blue,
      fontSize: "1rem", // 16px
      fontWeight: "300", // Light
      letterSpacing: "-0.01rem", // -0.16px
    },
    body2: {
      color: colors.blue,
      fontSize: "0.875rem", // 14px
      fontWeight: "300", // Light
      letterSpacing: "-0.00875rem", // -0.14px
    },
    button: {
      color: colors.white,
      fontSize: "0.875rem", // 14px
      fontWeight: "400", // Regular
      letterSpacing: "-0.05rem", // -0.8px
    },
    caption: {
      color: colors.blue,
      fontSize: "0.75rem", // 12px
      fontWeight: "300", // Light
      letterSpacing: "-0.0075rem", // -0.12px
    },
    overline: {
      color: colors.blue,
      fontSize: "0.625rem", // 10px
      fontWeight: "400", // Regular
      letterSpacing: "-0.00625rem", // -0.1px
      textTransform: "capitalize",
    },
  },
  palette: {
    primary: {
      main: colors.coolBlue,
      light: colors.coolBlueLight,
      dark: colors.coolBlueDark,
    },
    secondary: {
      main: colors.green,
      light: colors.greenLight,
      dark: colors.greenDark,
    },
  },
  status: {
    danger: orange[500],
  },
  colors,
});

const theme = createTheme(glideTheme, {
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: glideTheme.colors.greenDark,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: glideTheme.colors.greenDark,
          },
        },
      },
    },
  },
  status: {
    danger: orange[500],
  },
});

export default theme;
