import { corkscrewApiFactory } from "@/api/corkcrew";
import { useUser } from "@/contexts/userContext";
import { getCorkscrewBaseUrl } from "@/utils/config";
import { useMemo } from "react";

const useCorkscrewApi = () => {
  const userCtx = useUser();
  const token = userCtx?.state?.token || "";
  const apiEndpoint =
    userCtx?.state?.apiEndpoint || getCorkscrewBaseUrl() || "";
  const orgId = userCtx?.state?.orgId || "";

  const factory = useMemo(() => {
    return corkscrewApiFactory(apiEndpoint, orgId, token);
  }, [token, apiEndpoint, orgId]);

  return factory;
};

export default useCorkscrewApi;
