import { useUser } from "@/contexts/userContext";
import { Box, Container, Typography } from "@mui/material";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import theme from "@/utils/theme";

const Footer = () => {
  const user = useUser();
  const [customerNumber, setCustomerNumber] = useState("");

  useEffect(() => {
    if (user?.state?.profile?.customerNumber) {
      setCustomerNumber(user.state.profile.customerNumber);
    }
  }, [user?.state?.profile?.customerNumber]);

  return (
    <Box
      component="footer"
      sx={{
        padding: "10px",
        backgroundColor: theme.colors.greenLightest,
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {customerNumber ? (
            <Typography sx={{ fontSize: "12px" }}>
              <span>Logged in as</span>{" "}
              <Box
                component="code"
                sx={{ letterSpacing: 1, fontSize: 12, fontWeight: 300 }}
              >
                {customerNumber}
              </Box>
            </Typography>
          ) : null}
          <Typography>
            Powered by{" "}
            <Link
              href="https://www.glidenow.com?utm_medium=myaccount&utm_source=direct&utm_campaign=my-account"
              target="_blank"
              rel="noopener"
            >
              Glide{" "}
              <img
                src="/icon.png"
                width={24}
                style={{ display: "inline-block", marginLeft: "8px" }}
              />
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
