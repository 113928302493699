import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "@/utils/theme";

export const UserCard = styled(Box)(() => ({
  borderRadius: theme.shape.borderRadius,
  margin: 1,
  marginLeft: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0 6px",
  flexWrap: "wrap",
}));

export const CardTypography = styled(Typography)(() => ({
  lineHeight: "25px",
  fontSize: "14px",
  color: theme.colors.greyDark,
}));

export const ActionsTypography = styled(Typography)(() => ({
  color: theme.colors.greenDark,
  fontSize: "14px",
  cursor: "pointer",
}));

export const Separator: React.FC = () => {
  return (
    <Box component="span" sx={{ padding: "0 1" }}>
      |
    </Box>
  );
};
