"use client";
import React, { createContext, useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { localStorageEffect } from "./effects";
import { useUser } from "./userContext";

export type UIState = {
  ready: boolean;
  brandId?: string;
  orgId?: string;
  apiEndpoint?: string;
  token?: string;
  name?: string;
  logoHorizontal?: {
    url: string;
  };
  logoSquare?: {
    url: string;
  };
  isLight: boolean;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  isCheckered: boolean;

  proofPagination: {
    lineItems: any[];
    proofId: string | null;
    proofName: string | null;
  };
};

export const defaultUiState = {
  ready: false,
  isLight: true,
  isCheckered: false,
  color1: "#EBEBEB",
  color2: "#8C8C8C",
  color3: "#CFD0D0",
  color4: "#C5C6C6",
  proofPagination: {
    lineItems: [],
    proofId: null,
    proofName: null,
  },
};

export const UIContext = createContext<UIState>(defaultUiState);

export const uiState = atom<UIState>({
  key: "ui_1",
  default: defaultUiState,
  effects: [localStorageEffect("glide_ui_state_1")],
});

function UIStateProvider({ children }: { children: React.ReactNode }) {
  const state = useRecoilValue(uiState);

  return <UIContext.Provider value={state}>{children}</UIContext.Provider>;
}

export { UIStateProvider };
