import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface IProps {
  message: string;
  defaultStyles?: boolean;
}

const GreyTypography = styled(Typography)(({ theme }) => ({
  background: theme.colors.greyLight,
}));

const NoDataFound: React.FunctionComponent<IProps> = ({
  message,
  defaultStyles,
  ...rest
}: IProps) => {
  return (
    <Box justifyContent="center">
      {defaultStyles ? (
        <GreyTypography
          sx={{
            fontSize: 2,
          }}
          {...rest}
        >
          {message}
        </GreyTypography>
      ) : (
        <Typography {...rest}>{message}</Typography>
      )}
    </Box>
  );
};

export default NoDataFound;
