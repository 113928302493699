import { id } from "@/utils/gid";
import type { IPublicOrderLineItem } from "@kartdavid/corkscrew-types/public";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { default as NextLink } from "next/link";
import React from "react";

import { formatUtcDate } from "@/utils/date";
import theme from "@/utils/theme";
import { styled } from "@mui/material/styles";
import { ProofStatus } from "@/api/corkcrew";

const BlueLink = styled(NextLink)(() => ({
  color: theme.colors.coolBlue,
  textDecoration: "none",
}));

const TextLabel = styled(Typography)(({}) => ({
  fontSize: "14px",
}));

interface IProps {
  orderId: string;
  orderNumber: string;
  orderStatus: ProofStatus;
  orderLineItems: IPublicOrderLineItem[];
  orderDate: Date;
  ordersPagePath: string;
  orderFileLink?: string;
  canDownloadInvoice: boolean;
}
const OrderListItem: React.FunctionComponent<IProps> = ({
  orderId,
  orderNumber,
  orderStatus,
  orderLineItems,
  orderDate,
  ordersPagePath,
  orderFileLink,
  canDownloadInvoice,
}: IProps) => {
  const { t } = useTranslation("OrderStatus");
  const { t: tOrderList } = useTranslation("OrderList");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const findAvailableProof = () => {
    return orderLineItems.find((item) => item.status === "ProofPending");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderStatusAction = (status: {
    id: string;
    name: string;
    slug: string;
  }) => {
    return status?.name || "-";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        ":not(:first-child)": {
          marginTop: 2,
        },
      }}
    >
      <Grid container sx={{ display: { xs: "none", md: "block" } }}>
        <Grid item xs={12}>
          <Box sx={{ display: { sm: "none", md: "initial" } }}>
            <Box sx={{ display: "flex" }}>
              <Box flex={1}>
                <BlueLink href={`${ordersPagePath}/${id(orderId)}`}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxWidth: "110px",
                      textAlign: "center",
                      p: "6px 20px",
                      color: theme.colors.greenDark,
                      fontSize: "13px !important",
                      fontWeight: "500",
                      borderRadius: 2.5,
                      border: `1px solid ${theme.colors.greenDark}`,
                      ":hover": {
                        color: theme.colors.white,
                        bgcolor: theme.colors.greenDark,
                      },
                    }}
                  >
                    <Box component="span">{orderNumber}</Box>
                    <LaunchIcon sx={{ width: 16, heigh: 16, ml: 0.5 }} />
                  </Typography>
                </BlueLink>
              </Box>
              <Box flex={1}>
                <TextLabel>{formatUtcDate(orderDate)}</TextLabel>
              </Box>
              <Box flex={1}>{renderStatusAction(orderStatus)}</Box>
              {canDownloadInvoice ? (
                <Box flex={1} sx={{ display: "flex", alignItems: "center" }}>
                  <TextLabel
                    sx={{
                      color: theme.colors.greenDark,
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "none",
                    }}
                  >
                    Reorder
                  </TextLabel>
                  {orderFileLink ? (
                    <Button
                      href={orderFileLink}
                      variant="contained"
                      LinkComponent="a"
                      download
                      target="_blank"
                      sx={{ p: 0, minWidth: 28, minHeight: 28, ml: 2 }}
                    >
                      <FileDownloadIcon sx={{ fontSize: "18px" }} />
                    </Button>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: { md: "none" },
          borderRadius: "9px",
          p: 1.5,
          border: `1px solid ${theme.colors.greenOriginal}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box mb={1}>
            <TextLabel sx={{ fontWeight: "bold" }}>
              {tOrderList("orderNumber")}
            </TextLabel>
            <NextLink href={`${ordersPagePath}/${id(orderId)}`}>
              <TextLabel sx={{ display: "flex", alignItems: "center" }}>
                <Box component="span">{orderNumber}</Box>
                <LaunchIcon sx={{ width: 16, heigh: 16, ml: 0.5 }} />
              </TextLabel>
            </NextLink>
          </Box>
          <Box mb={1}>
            <TextLabel sx={{ fontWeight: "bold" }}>
              {tOrderList("orderDate")}
            </TextLabel>
            <TextLabel>{formatUtcDate(orderDate)}</TextLabel>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <TextLabel sx={{ fontWeight: "bold" }}>
              {tOrderList("status")}
            </TextLabel>
            {renderStatusAction(orderStatus)}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ fontSize: "14px !important", fontWeight: "semibold" }}
              LinkComponent={NextLink}
              href={`${ordersPagePath}/${id(orderId)}`}
              variant="outlined"
            >
              View
            </Button>
            {orderFileLink ? (
              <Button
                variant="contained"
                href={orderFileLink}
                LinkComponent="a"
                download
                target="_blank"
                sx={{ p: 0, minWidth: 36, minHeight: 36, ml: 0.5 }}
              >
                <FileDownloadIcon sx={{ fontSize: "18px" }} />
              </Button>
            ) : null}
            {/* <Button
              variant="contained"
              sx={{
                p: 0,
                minWidth: 36,
                minHeight: 36,
                ml: 0.5,
                background: theme.colors.greenDark,
              }}
            >
              <ReplayIcon sx={{ fontSize: "18px" }} />
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderListItem;
