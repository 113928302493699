import React from "react";
import { Box, Skeleton } from "@mui/material";

const OrdersListSkeleton: React.FC = () => {
  return (
    <Box>
      {[...Array(20)].map((item) => (
        <Skeleton
          key={item}
          width="100%"
          variant="rounded"
          sx={{ mb: 2, height: { xs: "118px", md: "40px" } }}
        />
      ))}
    </Box>
  );
};

export default OrdersListSkeleton;
