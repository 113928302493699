import { DateTime } from "luxon";

import { DATE_TIME_FORMAT } from "@/constants";

export const formatUtcDate = (
  date?: Date | string,
  formatStr: string = DATE_TIME_FORMAT
): string => {
  if (!date) {
    return "-";
  }

  let d: DateTime;

  if (typeof date === "string") {
    d = DateTime.fromISO(date);
  } else {
    d = DateTime.fromJSDate(date);
  }

  if (!d.isValid) {
    return "-";
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  d.setZone(timeZone);

  return d.toFormat(formatStr);
};
