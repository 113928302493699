import "@/styles/globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { UserProvider } from "@/contexts/userContext";
import theme from "@/utils/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppLayout } from "@/components/organisms";
import { SnackbarProvider } from "notistack";
import { CartProvider } from "@/contexts/cartContext";
import RecoilRootWrapper from "@/components/RecoilRootWrapper";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { UIStateProvider } from "@/contexts/uiState";

Bugsnag.start({
  apiKey: "40f797790c4b14c8b3452e40f13943cc",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_RELEASE_STAGE,
  enabledReleaseStages: ["live", "staging"],
  autoTrackSessions: false,
});

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

const App = ({ Component, pageProps }: AppProps) => {
  if (!ErrorBoundary) {
    return null;
  }

  return (
    <ErrorBoundary>
      <RecoilRootWrapper>
        <UIStateProvider>
          <UserProvider>
            <CartProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider>
                  <AppLayout>
                    <Component {...pageProps} />
                  </AppLayout>
                </SnackbarProvider>
              </ThemeProvider>
            </CartProvider>
          </UserProvider>
        </UIStateProvider>
      </RecoilRootWrapper>
    </ErrorBoundary>
  );
};

export default appWithTranslation(App);
