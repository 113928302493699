import React from "react";
import { useUser } from "@/contexts/userContext";
import routes from "@/utils/routes";
import theme from "@/utils/theme";
import { Link } from "@mui/material";
import { default as NextLink } from "next/link";

const MyAccountLink: React.FC = () => {
  const {
    state: { shopifyOrderUrl },
  } = useUser();

  return (
    <Link
      component={NextLink}
      href={shopifyOrderUrl || routes.orders}
      sx={{
        color: theme.colors.greyDark,
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
      }}
    >
      My Account
    </Link>
  );
};

export default MyAccountLink;
