"use client";

import { websiteApiFactory } from "@/api/websiteapi";
import { useContext } from "react";
import { UserStateContext } from "@/contexts/userContext";
import { CartContext, cartState } from "@/contexts/cartContext";
import { useRecoilState } from "recoil";
import { PublicApprovedArtwork } from "@/types";

const websiteApi = websiteApiFactory("");

const useCart = () => {
  const userContext = useContext(UserStateContext);
  const [cart, setCart] = useRecoilState(cartState);

  return {
    id: cart.id,
    totalQuantity: cart.quantity,
    linesAdd: async (lines: PublicApprovedArtwork[]) => {
      let cid = cart.id;

      if (!cid) {
        setCart((cur) => {
          return { ...cur, status: "creating" };
        });
        const newCart = await websiteApi.createCart("GBP");
        setCart((cur) => {
          return { ...cur, id: newCart.id, status: "idle" };
        });
        cid = newCart.id;
      }

      setCart((cur) => {
        return { ...cur, status: "updating" };
      });
      await websiteApi.addToCart(cid, userContext?.state?.userId || "", lines);
      setCart((cur) => {
        return { ...cur, status: "idle", quantity: cur.quantity + 1 };
      });
    },
    status: cart.status,
  };
};

export default useCart;
