import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";

type Props = {
  suffix?: string;
};

const LoadingView: React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <LinearProgress />
      <Typography variant="h2" component="h2" align="center" my={4}>
        Loading {props.suffix ? props.suffix : null}...
      </Typography>
    </Box>
  );
};

export default LoadingView;
