"use client";

import React, { useEffect, useState } from "react";
import { Badge, Box, IconButton } from "@mui/material";
import { useUser } from "@/contexts/userContext";
import { websiteBaseUrl } from "@/utils/config";
import Image from "next/image";
import CartIconImage from "../../../public/assets/svgs/cart.svg";
import { cartState } from "@/contexts/cartContext";
import { useRecoilState } from "recoil";

const CartIcon = () => {
  const user = useUser();
  const [cart] = useRecoilState(cartState);
  const [cartId, setCartId] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [cartUrl, setCartUrl] = useState("/cart");

  useEffect(() => {
    setCartId(cart.id || "");
    setQuantity(cart.quantity);
  }, [cart.id, cart.quantity]);

  useEffect(() => {
    if (user?.state?.cartUrl) {
      setCartUrl(user.state.cartUrl);
    }
  }, [user?.state?.cartUrl]);

  const params = new URLSearchParams();
  if (cartId) {
    params.set("siCartId", cartId);
  }

  return (
    <Box color="secondary" sx={{ position: "relative" }}>
      <Badge
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
        badgeContent={quantity}
        color="secondary"
      ></Badge>

      <IconButton
        aria-label="add to shopping cart"
        href={`${websiteBaseUrl}${cartUrl}?${params.toString()}`}
        size="large"
      >
        <Image
          src={CartIconImage}
          width={19}
          height={19}
          alt="cart icon image"
        />
      </IconButton>
    </Box>
  );
};

CartIcon.displayName = "CartIcon";

export default CartIcon;
