import React from "react";
import type { IPublicOrderLineItem } from "@kartdavid/corkscrew-types/public";
import { styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "next-i18next";
import { defaultTranslations, DimensionsText } from "../atoms";
import theme from "@/utils/theme";

const StyledStatus = styled(Typography)(() => ({
  color: theme.colors.greyDivider,
  fontSize: theme.typography.pxToRem(16),
}));

interface IOrderProps {
  orderNumber: string;
  orderHistory: IPublicOrderLineItem[];
}

const OrderDetails: React.FC<IOrderProps> = ({
  orderNumber,
  orderHistory,
}: IOrderProps) => {
  const { t } = useTranslation("OrderDetails");
  const { t: tStatus } = useTranslation("OrderStatus");

  const renderStatus = (status: string) => {
    switch (status) {
      case "ProofAccepted":
        return (
          <Typography
            sx={{
              color: theme.colors.coolBlue,
              textTransform: "none",
              cursor: "pointer",
            }}
          >
            Edit
          </Typography>
        );
      case "Print":
        return <StyledStatus>{tStatus("Print.name")}</StyledStatus>;
      case "ProofPending":
        return <StyledStatus>Awaiting approval</StyledStatus>;
    }
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              flex: 1,
              flexShrink: 0,
              fontSize: {
                xs: theme.typography.pxToRem(14),
                md: theme.typography.pxToRem(16),
              },
            }}
          >
            Order
            <Box
              component="span"
              sx={{
                fontSize: {
                  xs: theme.typography.pxToRem(14),
                  md: theme.typography.pxToRem(16),
                },
                color: theme.colors.coolBlue,
                marginLeft: 4,
              }}
            >
              {orderNumber}
            </Box>
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.pxToRem(14),
                md: theme.typography.pxToRem(16),
              },
            }}
          >
            {t("orderDetails")}
          </Typography>
        </AccordionSummary>
        {orderHistory.map((order) => (
          <React.Fragment key={order.id}>
            <Divider />
            <AccordionDetails sx={{ paddingTop: 2, paddingBottom: 2 }}>
              <Grid container alignItems="center">
                <Grid item xs={6} sm={3} sx={{ marginBottom: 2 }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(14),
                        md: theme.typography.pxToRem(16),
                      },
                      marginRight: "1em",
                    }}
                  >
                    {order.productName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} sx={{ marginBottom: 2 }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(14),
                        md: theme.typography.pxToRem(16),
                      },
                    }}
                  >
                    <DimensionsText
                      width={order.widthMM}
                      height={order.heightMM}
                      units="mm"
                      displayAs="cm"
                      translations={defaultTranslations}
                    />
                  </Typography>
                </Grid>
                <Grid item sm={3} sx={{ marginBottom: 2 }}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(14),
                        md: theme.typography.pxToRem(16),
                      },
                      [theme.breakpoints.down("md")]: {
                        textAlign: "right",
                      },
                    }}
                  >
                    Qty {order.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} sx={{ marginBottom: 2 }}>
                  <Box
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(14),
                        md: theme.typography.pxToRem(16),
                      },
                      [theme.breakpoints.down("md")]: {
                        textAlign: "right",
                      },
                    }}
                  >
                    {renderStatus(order.status)}
                  </Box>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.pxToRem(14),
                          md: theme.typography.pxToRem(16),
                        },
                      }}
                    >
                      Material: {order.materialSku}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {/* <IconButton>
                      <CancelIcon />
                    </IconButton> */}
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </React.Fragment>
        ))}
      </Accordion>
    </>
  );
};

export default OrderDetails;
