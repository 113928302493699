import React, { FC, memo } from "react";
import { Units } from "@/utils/dimensions";

interface IProps {
  width: number;
  height: number;
  displayAs?: Units;

  units: Units;

  translations: {
    cm: string;
    in: string;
    mm: string;
    m: string;
  };
}

export const defaultTranslations: IProps["translations"] = {
  cm: "cm",
  in: "in",
  mm: "mm",
  m: "m",
};

export const formatDimensions = (props: IProps) => {
  const t = (k: keyof IProps["translations"]) =>
    (props.translations || defaultTranslations)[k];
  const formatter = new Intl.NumberFormat();

  if (props.displayAs) {
    const width = formatter.format(props.width / 10);
    const height = formatter.format(props.height / 10);

    if (props.displayAs === "cm" && props.units === "mm") {
      return `${width} x ${height}${t(props.displayAs || "cm")}`;
    }
  }

  const width = formatter.format(props.width);
  const height = formatter.format(props.height);

  return `${width} x ${height}${t(props.units)}`;
};

export const DimensionsText: FC<IProps> = memo((props) => {
  return <>{formatDimensions(props)}</>;
});

DimensionsText.displayName = "DimensionsText";
