import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "next-i18next";

type IPayment = any;
import {
  ActionsTypography,
  CardTypography,
  Separator,
  UserCard,
} from "./style";

interface IProps {
  payment: IPayment;
  handleRemove: (payment: IPayment) => void;
  handleEdit: (payment: IPayment) => void;
  handleSetDefault: (payment: IPayment) => void;
}
const UserPaymentCard = ({
  payment,
  handleRemove,
  handleEdit,
  handleSetDefault,
}: IProps) => {
  const { t } = useTranslation("AccountSummary");
  const { t: tUI } = useTranslation("UI");

  return (
    <UserCard
      sx={{
        width: {
          xs: "100%",
          md: 400,
        },
      }}
    >
      <CardTypography>Visa ending is {payment.visaEnding}</CardTypography>
      <CardTypography>Expires: {payment.expiryDate}</CardTypography>
      <CardTypography>Name on card: {payment.nameOnCard}</CardTypography>
      <Box
        sx={{
          display: "flex",
          marginTop: 3,
        }}
      >
        <ActionsTypography onClick={() => handleEdit(payment)}>
          {tUI("buttons.edit")}
        </ActionsTypography>
        <Separator />
        <ActionsTypography onClick={() => handleRemove(payment)}>
          {tUI("buttons.remove")}
        </ActionsTypography>
        <Separator />
        {payment.default ? (
          <Box display="flex">
            <Typography>{t("defaults.billingAddress")}</Typography>
            <CheckCircleIcon />
          </Box>
        ) : (
          <ActionsTypography onClick={() => handleSetDefault(payment)}>
            {t("defaults.setAsDefault")}
          </ActionsTypography>
        )}
      </Box>
    </UserCard>
  );
};

export default UserPaymentCard;
